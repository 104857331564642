.App {
  text-align: center;
}

body {
  background: #000;
  overflow: hidden;
}

.MuiButton-label {
  color: #fff !important;
  text-transform: capitalize;
}

.makeStyles-root-1 .MuiDialogTitle-root {
  color: #000 !important;
  background-color: #fff !important;
}

.MuiPaper-root {
  border-radius: 0px !important;
}

#root {
  width: 100vw;
}
